<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                实力认可！非凸算法正式上线国泰君安证券
              </p>
              <div class="fst-italic mb-2">2022年12月16日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国泰君安证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/25/01-国泰君安.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸智能算法上线国泰君安证券，凭借高绩效、稳运行、强技术获得券商及管理人的高度认可。非凸科技在算法部署、运行、维护、优化、升级等方面提供技术和人力支持，以保障算法服务质量，助力机构缩短展业时间，实现更多价值。
                  </p>
                   <p>
                    自建团队需要大量人力成本和技术投入，隐性成本能否降低难以事先分析，绩效稳定性也不易把握......诸如此类因素，越来越多量化私募选择与非凸科技这样的三方算法交易服务商合作而非自建。非凸算法上线国泰君安证券后，能准确完成大额交易，提高交易效率，降低冲击成本，获取更多超额收益，与此同时，还吸引了国内众多知名量化私募排队申请试用。
                  </p>
                  <p>
                    当前，在算法交易领域，更多的是比拼算法和算力。非凸科技积累了大量算法人才，并每年耗资千万打造超算中心，不仅能实现模型和策略的高效迭代，还能保证算法的持续有效。目前，非凸人员数量实现了快速增长，工程和策略团队规模逐渐扩大，另外顶尖人才招募也在计划行列。未来，非凸科技也将在算力投入上持续加码，配合大数据、机器学习、深度学习等前瞻性技术，降低交易系统整体延迟，赋能金融机构创新发展。
                  </p>
                  <p>
                    此次，非凸科技与国泰君安证券达成总对总合作，推动了量化生态的共建。双方将在行情、交易、算法、策略等方面展开多维度、多层次业务与技术合作，为客户提供更优质的服务，更极致的体验。目前，在国泰君安证券ATX客户端上，已上线4个版本非凸算法，分别是：
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/25/02-策略.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    欢迎管理人根据自身需求，选择试用。
                  </p>
                  <p>
                    基于客户需求，提供优质产品和服务，是非凸不变的初心。期待与更多券商展开深入合作，坚守技术，持续创新，共同前行，实现卓越！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News25",
};
</script>

<style></style>
